.main-enter {
    opacity: 0;
    /* transform: translateX(0); */
    top: 0;
  }
.main-enter-active {
    opacity: 1;
    /* transform: translateX(400); */
    top: 45pt;
    transition: opacity 300ms, top 300ms;
}
.main-exit {
    opacity: 1;
    /* transform: translateX(400); */
    top: 45pt;
}
.main-exit-active {
    opacity: 0;
    /* transform: translateX(0); */
    top: 0;
    transition: opacity 300ms, top 300ms;
}

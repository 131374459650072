body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(to bottom left, rgba(239, 62, 45, 1), rgba(239, 62, 45, 0.6)) !important;
  /*Electric Yellow: #efee2d*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-page-header {
  background-color: transparent !important;
}
.ant-page-header-heading-title {
  color: white !important;
  font-size: 30px !important;
}

.ant-page-header-heading-sub-title {
  color: white !important;
  font-weight: 300 !important;
  font-size: 25px !important;
}

span {
  font-family: 'Lato', sans-serif !important;
  /* font-weight: 400 !important; */
}

h3, h2 {
  font-family: 'Lato', sans-serif !important;
}

/* .ant-page-header {
  border-bottom: 1px solid #ef3e2d;
  box-shadow: 0px 4px rgba(255, 255, 255, 0.1);;
} */

.triangle-right {
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  border-left: 100px solid rgba(239, 62, 45, 1);
  /* border-left: 100px solid red; */
  border-bottom: 50px solid transparent;
}

.triangle-left {
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  border-right: 100px solid rgba(239, 62, 45, 1);
  /* border-right: 100px solid red; */
  border-bottom: 50px solid transparent;
}

.triangle-down {
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-top: 100px solid rgba(239, 62, 45, 1);
}

.triangle-up {
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-bottom: 100px solid rgba(239, 62, 45, 1);
}

@media (max-width: 576px) {

  .triangle-left {
    display: none;
  }

  .triangle-right {
    display: none;
  }

}

@media (min-width: 577px) {
  .triangle-up {
    display: none;
  }

  .triangle-down {
    display: none;
  }
}